.gallery {
  list-style-type: none;
  margin-top: 4%;
}

.chord-name {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 1%;
}

.small-diagram {
  height: 50px;
  width: 50px;
}

.chord-button {
  border: none;
  padding: 12px;
  margin: 1% auto;
  border-radius: 5px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.small-diagram-buttons {
  display: inline-block;
}

.chord-button:hover, 
.chord-button:focus {
  background: #A3CDFF;
}
