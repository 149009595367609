.dark-header {
  margin-top: 50px;
}

.dark-header-h1, .dark-header-h2 {
  color: #1C3D66;
  padding-top: 1%;
}

.dark-header-h1 {
  font-size: 32px;
  font-weight: bold;
}

.dark-header-h2 {
  color: black;
  padding-bottom: 1%;
}

@media screen and (min-width: 40em) {
  .dark-header-h1 {
    font-size: 42px;
  }

  .dark-header-h2 {
    font-size: 24px;
  }
}

@media screen and (min-width: 100em) {
  .dark-header-h1 {
    font-size: 50px;
  }

  .dark-header-h2 {
    font-size: 28px;
  }
}