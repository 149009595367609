.small-diagram {
  position: relative;
  height: 50px;
  width: 50px;
  margin: 0 auto;
  background-image: url('../../../image-assets/diagram.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 3px;
  margin-bottom: 12%;
}

.small-open-diagram {
  position: relative;
  height: 50px;
  width: 50px;
  margin: 0 auto;
  background-image: url('../../../image-assets/open-diagram.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 3px;
  margin-bottom: 12%;
}

.small-barre {
  background-image: url('../../../image-assets/barre.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 10px;
  top: -5px;
  position: absolute;
}

.small-diagram:hover, .small-diagram:focus,
.small-open-diagram:hover, .small-open-diagram:focus {
  cursor: pointer;
}