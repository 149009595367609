.search-button {
  border: none;
  background: #1C3D66;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 40%;
  max-width: 160px;
  display: block;
  margin: 10px auto;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-button:hover, 
.search-button:focus {
  background: #A3CDFF;
  color: black;
}

.search-form {
  padding-top: 1%;
}

.key-choices {
  width: 40%;
  max-width: 160px;
  border: none;
  background: #1C3D66;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  padding: 8px;
}