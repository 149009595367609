* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  line-height: 1.5;
  text-align: center;
  background: white;
  color: black;
}
