.grid > * {
  padding: .1rem;
}

.grid {
    display: grid;
    gap: .1rem;
    list-style-type: none;
  }


@media screen and (min-width: 40em) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
    gap: .5rem;
  }
  .grid li {
      grid-column: span 2;
      grid-row: span 2;
  }
}