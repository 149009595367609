.link-list {
  position: absolute;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}

.link-list > a {
  color: #A3CDFF;
  font-size: 16px;
  width: 30%;
}

.link-list > a:visited {
  color: #A3CDFF;
}

.link-list > a:hover,
.link-list > a:focus {
  text-shadow: 1px 1px 1px #3E89E6;
}

@media screen and (min-width: 40em) {
  .link-list > a {
    font-size: 20px;
  }
}

@media screen and (min-width: 100em) {
  .link-list > a {
    font-size: 22px;
  }
}